<template>
  <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
    <div
      class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
      <span class="mr-2">
          {{ Math.abs(currentPage * items - (items)) }} - {{ length - currentPage * items > 0 ? currentPage * items : length }} of {{ queriedItems }}</span>
      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
    </div>
    <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
    <vs-dropdown-menu>

                <span v-for="i in 20" :key="i">
                    <vs-dropdown-item
                      v-if="i % 5 === 0"
                      @click="$emit('onChangeItemsPerPage', i)"
                    >
                        <span>{{i}}</span>
                    </vs-dropdown-item>
                </span>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
  export default {
    name: 'table-header',
    props: ["currentPage", "itemsPerPage", "length", "queriedItems"],
    data() {
      return {
        items: this.itemsPerPage === 5 ? 20 : this.itemsPerPage,
      }
    },
    watch: {
      itemsPerPage(value) {
        this.items = value;
      }
    }
  };
</script>

<style scoped>

</style>
