<template>
    <vs-td>
        <p class="product-name font-medium truncate" :title="data">{{ data }}</p>
    </vs-td>
</template>

<script>
    export default {
        name: 'string-row',
        props: ["data"]
    };
</script>

<style scoped>

</style>
