<template>
    <vs-td class="text-center">
        <vs-chip
            :color="getOrderStatusColor(data)"
            class="product-order-status text-center"
            >{{ data }}
        </vs-chip>
    </vs-td>
</template>

<script>
export default {
    name: "agent-status-row",
    props: ["data"],
    methods: {
        getOrderStatusColor(data) {
            if (data === "DONE") {
                return "success";
            } else if (data === "PENDING") {
                return "dark";
            }
            return "primary";
        },
    },
};
</script>

<style scoped></style>
