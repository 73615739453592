<template>
    <div>
        <breadcrumb
            name="Requests Logs"
            title="Requests Logs Requests"
            subtitle="List"
        />

        <div id="data-list-thumb-view" class="data-list-container">
            <vs-tabs v-model="request_type" alignment="fixed">
                <vs-tab label="All" value="all">
                    <div></div>
                </vs-tab>
                <vs-tab label="RSA Requests" value="rsa">
                    <div></div>
                </vs-tab>
                <vs-tab label="Service Appointment Requests" value="booking">
                    <div></div>
                </vs-tab>
                <vs-tab label="Trade In Requests" value="trade_in">
                    <div></div>
                </vs-tab>
                <vs-tab label="Contact Us Requests" value="contact_us">
                    <div></div>
                </vs-tab>
                <vs-tab label="Test Drive Requests" value="test_drive">
                    <div></div>
                </vs-tab>
            </vs-tabs>
            <vs-table ref="table" :sst="true" :data="logs">
                <div
                    slot="header"
                    class="
                        flex flex-wrap-reverse
                        items-center
                        flex-grow
                        justify-between
                    "
                >
                    <div class="flex flex-wrap-reverse items-center">
                        <vs-button
                            color="primary"
                            type="filled"
                            @click="exportData"
                            >Export</vs-button
                        >
                    </div>

                    <div
                        class="
                            flex flex-wrap-reverse
                            items-center
                            data-list-btn-container
                        "
                    ></div>

                    <!-- ITEMS PER PAGE -->
                    <table-header
                        :current-page="currentPage"
                        :items-per-page="itemsPerPage"
                        :length="logs.length"
                        @onChangeItemsPerPage="itemsPerPage = $event"
                    />
                </div>

                <template slot="thead">
                    <vs-th>Status</vs-th>
                    <vs-th>Request ID</vs-th>
                    <vs-th>Request Type</vs-th>
                    <vs-th>Agent Name</vs-th>
                    <vs-th>Agent Email</vs-th>
                    <vs-th>Date</vs-th>
                    <vs-th>Time</vs-th>
                </template>

                <template slot-scope="{ data }">
                    <tbody>
                        <vs-tr
                            :data="tr"
                            :key="indextr"
                            v-for="(tr, indextr) in data"
                        >
                            <AgentStatusRow
                                :data="
                                    tr.status.toUpperCase().replace('_', ' ')
                                "
                            />
                            <string-row :data="tr.request" />
                            <string-row :data="handleType(tr.request_type)" />
                            <string-row
                                :data="tr.admin ? tr.admin.name : '--'"
                            />
                            <string-row
                                :data="tr.admin ? tr.admin.email : '--'"
                            />
                            <string-row
                                :data="tr.created_at | moment('DD MMMM YYYY')"
                            />
                            <string-row
                                :data="tr.created_at | moment('h:m A')"
                            />
                        </vs-tr>
                    </tbody>
                </template>
            </vs-table>
        </div>
        <vs-pagination :total="totalItems" v-model="page" />
    </div>
</template>

<script>
import Breadcrumb from "../../../components/general/breadcrumb";
import TableHeader from "../../../components/table/table-header";
import StringRow from "../../../components/table/rows/string-row";
import AgentStatusRow from "../../../components/table/rows/agent-status-row";

const types = {
    0: "",
    1: "rsa",
    2: "booking",
    3: "trade_in",
    4: "contact_us",
    5: "test_drive",
};
export default {
    components: {
        Breadcrumb,
        TableHeader,
        AgentStatusRow,
        StringRow,
    },
    data() {
        return {
            request_type: 0,
            logs: [],
            itemsPerPage: 20,
            isMounted: false,
            page: 1,
            totalItems: 0,
        };
    },
    watch: {
        request_type() {
            this.page = 1;
            this.getData(types[this.request_type]);
        },
        page() {
            this.getData(types[this.request_type]);
        },
        itemsPerPage() {
            this.getData(types[this.request_type]);
        },
    },
    methods: {
        exportData() {
            let queryString = `?request_type=${types[this.request_type]}`;
            window.location.href =
                process.env.VUE_APP_ADMIN_URL +
                "/" +
                "requests/export" +
                queryString;
        },
        handleType(type) {
            switch (type) {
                case "App\\Models\\ContactUs":
                    return "Contact Us";
                case "App\\Models\\Bookingservice":
                    return "Service Appointment";
                case "App\\Models\\TestDriveRequest":
                    return "Test Drive";
                case "App\\Models\\Rsa":
                    return "RSA";
                case "App\\Models\\TradeInRequest":
                    return "Trade In";
                default:
                    return type;
            }
        },
        getData(type) {
            this.$vs.loading();
            const queryString = `per_page=${this.itemsPerPage}&page=${this.page}&request_type=${type}`;
            this.$httpAdmin
                .get("requests/log?" + queryString, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.auth.token,
                    },
                })
                .then((r) => {
                    this.logs = r.data.data.data;
                    this.$vs.loading.close();
                    this.totalItems = Math.ceil(
                        r.data.data.total / this.itemsPerPage
                    );
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
    },
    mounted() {
        this.isMounted = true;

        this.getData(types[this.request_type]);
    },
    computed: {
        currentPage() {
            if (this.isMounted) {
                return this.$refs.table.currentx;
            }
            return 0;
        },
    },
};
</script>

<style lang="scss">
#data-list-thumb-view .vs-con-table .vs-table tr td:first-child {
    padding: 30px;
}
</style>
