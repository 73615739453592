<template>
  <div class="breadcrumb">
    <span class="title">{{name}}</span>
    <span class="separator ml-3 mr-3">|</span>
    <div class="mt-2">
      <span @click="$router.push('/')" class="back-to mr-2">
        <home-icon size="1x" class="arrow" color="primary"/>
      </span>
      <span class="mr-2">
        <chevrons-right-icon size="1x" class="arrow"/>
      </span>
      <span class="component-title mr-2" @click="$emit('onTitleClicked')">{{title}}</span>
      <span>
        <chevrons-right-icon size="1x" class="custom-class"/>&nbsp;
      </span>
      <span class="current-component">{{subtitle}}</span>
    </div>
  </div>
</template>

<script>
    import { ChevronsRightIcon, HomeIcon  } from 'vue-feather-icons';
    export default {
        name: 'breadcrumb',
        props: {
            name: {
                type: String,
            },
            title: {
                type: String,
            },
            subtitle: {
                type: String,
            }
        },
        components: {
            ChevronsRightIcon, HomeIcon
        }
    };
</script>

<style scoped lang="scss">
  @import "../../assets/scss/components/breadcrumb";

</style>
